  <template>
  <div class="login-container">
    <div class="logo">
      <img v-if="!isIstore" style="height: 60px" src="../../assets/sfclogo.png" />
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <h3 class="title">

        {{ isIstore ? '信息化管理平台' : '三态股份信息化管理平台' }}
      </h3>
      <el-row v-show="!isCode">
        <el-form-item prop="username" :error="errorMsg">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            v-model="loginForm.username"
            name="username"
            type="text"
            auto-complete="on"
            placeholder="用户名"
            @blur="checkUser"
            @keyup.enter.native="checkUser"
          />
        </el-form-item>
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :type="pwdType"
            v-model="loginForm.password"
            @keyup.enter.native="handleLogin"
            name="password"
            auto-complete="on"
            placeholder="密码"
            ref="userPwd"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="pwdType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
        <el-button
          type="text"
          size="small"
          @click="
            $router.push({
              name: 'findPassword',
              params: { name: loginForm.username },
            })
          "
          >忘记密码</el-button
        >
        <div class="clear"></div>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin"
            :disabled="validateFalse"
            >登录</el-button
          >
        </el-form-item>
      </el-row>
      <div class="code-tab" v-show="isCode">
        <!-- <h5>钉钉扫码登录</h5> -->
        <div class="qrcode-box">
          <div id="login_container"></div>
        </div>
      </div>
      <el-row style="margin: 10px auto; text-align: center">
        <el-button plain size="mini" v-if="!isCode" @click="changeLog()"
          >扫码登录</el-button
        >
        <el-button plain size="mini" v-if="isCode" @click="changeLog()"
          >账号密码登录</el-button
        >
      </el-row>
    </el-form>
    <div class="footer-bottom">
      Copyright © 2021 <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">{{ editionStr }}</a> All right reserved
    </div>
  </div>
</template>

<script>
import { validateUser, getLocalIp } from '@/api/login'
import { getIp, toLink } from '@/utils/index'
// import { getPowerInfo } from '@/utils/auth'
// import { getDepartTree } from '@/api/role.js'
// import { getStyle } from '@/api/style.js'

export default {
  name: 'Login',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else if (value.length < 5) {
        callback(new Error('密码不能小于5位'))
      } else {
        callback()
      }
    }

    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        password: [
          { required: true, trigger: 'blur', validator: validatePass }
        ]
      },
      loading: false,
      pwdType: 'password',
      redirect: undefined,
      isOldUser: '',
      isFirstLogin: '',
      errorMsg: '',
      validateFalse: false,
      isCode: false
    }
  },
  computed: {
    editionStr() {
      let str = '粤ICP备15079153号-1'
      if (window.location.host.indexOf('szecommerce.com') > -1) {
        str = '粤ICP备15079153号-1'
      } else if (window.location.host.indexOf('suntekcorps.cn') > -1) {
        str = '粤ICP备15079153号-2'
      } else {
        str = '粤ICP备15050246号-7'
      }
      return str
    },
    isIstore() {
      return window.location.host.indexOf('szecommerce.com') > -1 || window.location.host.indexOf('suntekcorps.cn') > -1
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.query && route.query.redirect) {
          let url = route.query.redirect
          Object.keys(route.query).forEach(key => {
            if (key !== 'redirect') {
              url += '&' + key + '=' + route.query[key]
            }
          })
          this.redirect = url
        }
      },
      immediate: true
    },
    handler(newval, oldval) {
      if (newval) {
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      }
    }
  },
  created() {
    this.getUserIp()
  },
  mounted() {
    const url = encodeURIComponent('http://account.suntekcorps.com:8080/login')
    const goto = encodeURIComponent(
      'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingoaxb8dpak3bcozxkpx&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=' +
        url
    )

    // eslint-disable-next-line
    var obj = DDLogin({
      id: 'login_container', // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
      goto: goto, // 请参考注释里的方式
      style: 'border:none;background-color:#FFFFFF;padding: 0 10px 10px;',
      width: '250',
      height: '300'
    })
    console.log(obj)
    if (typeof window.addEventListener !== 'undefined') {
      window.addEventListener('message', this.handleMessage, false)
    } else if (typeof window.attachEvent !== 'undefined') {
      window.attachEvent('onmessage', this.handleMessage)
    }
  },
  methods: {
    getUserIp() {
      getLocalIp().then((res) => {
        console.log(res, 'ip')
        this.$store.dispatch('setUserIp', res.data || '')
      }).catch(() => {
        this.$store.dispatch('setUserIp', '')
      })
    },
    changeLog() {
      this.isCode = !this.isCode
    },
    handleMessage() {
      var origin = event.origin
      if (origin === 'https://login.dingtalk.com') {
        // 判断是否来自ddLogin扫码事件。
        var loginTmpCode = event.data
        // 获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        console.log('loginTmpCode', loginTmpCode)
        this.$store.dispatch('DingLogin', loginTmpCode).then(() => {
          // const usId = this.$route.query.usId;
          // if (usId) {
          //   this.$router.push({
          //     path: "/" || "/userCenter",
          //     query: { usId: usId },
          //   });
          // } else {
          //   this.$router.push({ path: "/" || "/userCenter" });
          // }
          toLink().then(path => {
            if (this.redirect) {
              window.location.href = this.redirect
            } else {
              this.$router.push({ path: path })
            }
          })
        })
      }
    },
    // 验证这个用户是否存在
    checkUser() {
      if (!this.loginForm.username) {
        this.$message({
          message: '用户名不能为空~',
          type: 'warning'
        })
        return false
      } else {
        // 存在
        this.errorMsg = ''
        const params = {
          username: this.loginForm.username,
          state: 1
          // status: 1
        }
        validateUser(params)
          .then((response) => {
            if (response.code === '000000') {
              this.validateFalse = false
              this.$refs.userPwd.focus()
              // 判断是否是老用户
              this.isOldUser = response.data.isOldUser
              if (this.isOldUser === true) {
                this.$confirm(
                  '您是老用户因此要修改密码后才能登陆,是否现在去修改密码?',
                  '提示',
                  {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }
                )
                  .then(() => {
                    this.$router.push({ path: 'findPassword' })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: '已取消'
                    })
                  })
              }
            } else {
              this.errorMsg = response.message
              this.validateFalse = true
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    handleLogin() {
      if (!this.loginForm.username) {
        this.$message({
          message: '用户名不能为空~',
          type: 'warning'
        })
        return false
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.$store
              .dispatch('Login', this.loginForm)
              .then(() => {
                this.loading = false
                // utils公共方法获取面板配置，跳转地址path
                toLink().then(path => {
                  console.log(path, 'dd')
                  if (this.redirect) {
                    window.location.href = this.redirect
                  } else {
                    this.$router.push({ path: path })
                  }
                })

                // const usId = this.$route.query.usId;
                // if (info.userInfo.departmentId === 38996) { //此部门跳转新用户中心页面  8组测试 592  正式 38996
                //   if (usId) {
                //     this.$router.push({
                //       path: "/" || "/userCenter",
                //       query: { usId: usId },
                //     });
                //   } else {
                //     // this.$router.push({ path: '/' || '/userCenter' })
                //     this.$router.push({ path: "/resetPassword" });
                //   }
                // } else {
                //   this.$router.push({ path: "/userCenterOld" });
                // }
                // 因为进度条（NProgress）问题，所以把获取用户信息改到进度条控制器中
                // this.$store.dispatch("GetUserInfo").then(()=>{
                //   this.isFirstLogin = this.$store.getters.roles.userInfo.isFirstLogin;
                // console.log("isFirstLogin", this.isFirstLogin);
                // this.loading = false;
                // if (this.isFirstLogin === true) {
                //   this.$router.push({ path: "resetPassword" });
                // } else {
                //   this.$router.push({ path: this.redirect || "/userCenter" });
                //   // this.$router.push({ path: this.redirect || "/" });
                // }
                // })
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
    // toLink() {
    //   // 跳转看板在点击登录和扫码登录都需要判断，抽调成公共的
    //   const info = getPowerInfo()
    //   // 获取公司架构，查询用户部门是否配置看板，如果有跳看板页面，没有跳旧用户中心
    //   getDepartTree()
    //     .then((res) => {
    //       const data = res.data
    //       if (data.length > 0) {
    //         // 查询 用户 树形部门 通过多个部门 如果风格都设置了 取最接近部门的风格
    //         const newLevel = this.getParent(
    //           data,
    //           info.userInfo.departmentId,
    //           info.userInfo.userId
    //         )
    //         const params = {
    //           userId: newLevel
    //         }
    //         getStyle(params)
    //           .then((res) => {
    //             if (res.data.length > 0) {
    //               this.$router.push({ path: '/userCenterNew' })
    //             } else {
    //               this.$router.push({ path: '/' })
    //             }
    //           })
    //           .catch(() => {
    //             this.$router.push({ path: '/' })
    //           })
    //       } else {
    //         this.$router.push({ path: '/' })
    //       }
    //     })
    //     .catch(() => {})
    // },
    // getParent(data, id, userId) {
    //   let idStr = ''
    //   function find(data, id) {
    //     data.forEach((item) => {
    //       if (item.udId === id) {
    //         idStr = item.longId
    //       } else if (item.children) {
    //         find(item.children, id)
    //       }
    //     })
    //   }
    //   find(data, id)
    //   const ids = idStr.split('-')
    //   ids.push(userId)
    //   return ids.filter((i) => i !== '').join(',')
    // }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    .code-tab {
      // height: 248px;
      // background: #ccc;
      width: 100%;
      text-align: center;
      h5 {
        font-size: 16px;
        color: #2f2c2c;
      }
    }
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title {
    font-size: 26px;
    font-weight: 400;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .clear {
    clear: both;
  }
}
.logo {
  position: absolute;
  display: flex;
  height:60px;
  line-height: 60px;
  left: 5px;
  top: 10px;
  vertical-align: middle;
  color:#fff;
}
.footer-bottom {
  line-height: 44px;
  flex-grow: 1;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  font-weight: 500;
  color: #c6c8d1;
  text-align: center;
  width: 100%;
  border-top: 1px solid #3f3b5c;
}

</style>
